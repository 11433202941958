<template>
    <div>

        <ModalSimple title="Search filters" v-model="filtersModal" confirm="View results" @confirm="filtersModal = false;">
            <div class="searchFiltersGrid">
                <Input name="Load ID" v-model="settings.loadID" placeholder="Load ID"/>

                <div class="input">
                    <div class="_header">
                        <label>Origin</label>
                    </div>
                    <AutoComplete v-model="settings.origin" placeholder="Origin" class="autoCompleteBroker" type="locations"/>
                </div>

                <div class="input">
                    <div class="_header">
                        <label>Destination</label>
                    </div>
                    <AutoComplete v-model="settings.destination" placeholder="Destination" class="autoCompleteBroker" type="locations"/>
                </div>

                <div class="input">
                    <div class="_header">
                        <label>Broker</label>
                    </div>
                    <AutoComplete v-model="settings.broker" placeholder="Broker" class="autoCompleteBroker" type="brokers"/>
                </div>
            </div>
        </ModalSimple>

        <PageHeader title="Search loads">
            <button @click="filtersModal = true" class="default medium round _icon">Filters<i class="fa-solid fa-filter"></i></button>
        </PageHeader>

        <div class="wrapper searchGrid">

            <div class="listHeader">
                <h2>Loads • {{ loads.total ? loads.total : 0 }}</h2>
                <div class="sortOptions">
                    <DateRangePicker2 v-tooltip="'Search loads by date'" v-model:from="settings.dateStart" v-model:to="settings.dateEnd"/>
                    <DispatchSelector v-tooltip="'Search loads by dispatcher'" v-model="settings.dispatcher"/>
                    <CarrierSelector v-tooltip="'Search loads by carrier'" v-model="settings.carrier"/>
                </div>
            </div>

            <div class="listOfLoads" v-if="loads && loads.list">

                
                <div class="load header">
                    <div>#</div>
                    <div>Date</div>
                    <div>Carrier</div>
                    <div>Origin</div>
                    <div>Destination</div>
                    <div>Rate</div>
                    <div>Broker</div>
                    <div>Weight</div>
                    <div>Status</div>
                    <div>Dispatcher</div>
                    <div>Driver</div>
                    <div></div>
                    <div></div>
                </div>
                <div>
                    <Load class="load" :key="item._id" v-for="item of loads.list" :load="item" @dblclick="viewLoad(item)" @view="viewLoad"/>
                </div>
            </div>

            <div class="pagination" v-if="loads.list && loads.pages && loads.pages > 1">
                <button class="arrow" :disabled="settings.page <= 1" @click="nextPage(-1)"><i class="fa-solid fa-arrow-left"></i></button>
                <div class="pageNum">{{ settings.page }}</div>
                <button class="arrow" @click="nextPage(1)" :disabled="settings.page >= loads.pages"><i class="fa-solid fa-arrow-right"></i></button>
            </div>

        </div>
    </div>
</template>

<script>
import Load from '../../components/dispatch/Load.vue'
import CarrierSelector from '../../components/dispatch/CarrierSelector.vue'
import DateRangePicker2 from '../../components/DateRangePicker2.vue'
import DispatchSelector from '../../components/dispatch/DispatchSelector.vue'

import moment from 'moment'

    export default {
        components: {
            Load,
            DateRangePicker2,
            CarrierSelector,
            DispatchSelector
        },
        data() {
            return {
                loads: {},
                filtersModal: true,
                settings: {
                    page: 1,
                    carrier: '',
                    dateStart: moment.utc().subtract(1, 'year').format('MM/DD/YYYY')
                },
                timeout: null
            }
        },
        methods: {
            handleSearchLoads(timeout = 600) {

                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    let params = new URLSearchParams(this.settings).toString();
                
                    this.ajax('getLoads', {
                        url: `/dispatch/searchLoads?${params}`,
                        method: 'GET',
                    }, (err, body) => {
                        if(err) return;
                        this.loads = body;
                    });
                }, timeout);
            },
            nextPage(num) {
                this.settings.page += num;
            },
            viewLoad(load) {
                this.$store.state.loadManager.viewLoad = load._id;
            },
        },
        mounted() {
            this.handleSearchLoads(1);
        },
        watch: {
            settings: {
                deep: true,
                handler() {
                    this.handleSearchLoads(600);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>


.listOfLoads {
    border-radius: $borderRadius;
    background: $bgSecondary;
    padding: 20px;
    box-shadow: $boxShadow;
    .load {
        display: grid;
        grid-template-columns: 50px 100px minmax(0, 1fr) 150px 150px 75px minmax(0, 1fr) 75px 120px minmax(0, 1fr) minmax(0, 1fr) 20px 20px 20px;
        height: 40px;
        align-items: center;
        padding: 5px 5px;
        column-gap: 10px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
        &.header {
            font-weight: 500;
            border-radius: $borderRadius;
            background: $buttonColor2;
            height: 50px;
        }
    }
}


.listHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sortOptions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
    }
}

.searchGrid {
    display: grid;
    row-gap: 20px;
}


.pagination {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 44px 50px 44px;
    column-gap: 10px;
    .pageNum {
        text-align: center;
        font-size: 20px;
    }
    .arrow {
        width: 44px;
        height: 44px;
        border: 1px solid $borderColor;
        background: transparent;
        border-radius: 50%;
        font-size: 16px;
        color: $text;
        transition: ease 0.3s;
        &:hover {
            background: $buttonColor;
            color: #fff;
        }
        &:disabled {
            background: $borderColor;
            color: inherit;
            cursor: not-allowed;
        }
    }
}

.searchFiltersGrid {
    display: grid;
    row-gap: 10px;
}
</style>